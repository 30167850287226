import "./SkillDetailStyles.css"


import React, {Component} from 'react'


class SkillDetail extends Component {
    render(){
        return (
            <div className="skill">
                <img src={this.props.imgsrc} alt={this.props.alt}></img>
                <p>{this.props.text}</p>
            </div>
        )
    }
}

export default SkillDetail
import "./PandEbuttonStyles.css"


import React from 'react'
import {Link} from "react-router-dom"
import {FaUserCog, FaSuitcase} from "react-icons/fa"

const PandE = () => {
  return (
    <div className="PandE">
    <section id="projects">
      <div className="card card-limit">
        <h1 className="center">
          <FaUserCog size={30} style={{color:"#000", marginRight: "1rem"}}/>
          <Link to="/projects" className="hover-style ">Projects</Link>
        </h1>
      </div>
    </section>

    <section id="experience">
      <div className="card card-limit">
        <h1 className="center">
          <FaSuitcase size={30} style={{color:"#000", marginRight: "1rem"}}/>
          <Link to="/experience" className="hover-style ">Experience</Link>
        </h1>
      </div>
    </section>
    </div>
  )
}

export default PandE
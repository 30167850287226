import "./ProjectCardStyles.css"


import React, {Component} from 'react'
import { NavLink } from "react-router-dom"



class Work extends Component {
    render(){
        return (
            <div className="card custom-shadow-box mb-4 h-100">
                
                <img className="card-img-top  " src={this.props.imgsrc} alt="img"/>
                
                <div className="card-header">
                    <h2 className=" my-2 text-bg-light">{this.props.title}</h2>
                </div>
                
                <div className="card-body d-flex flex-column">
                    
                        <div className="text-black text-start text-wrap mb-2">
                            {this.props.text}
                        </div>

                        { (this.props.view || this.props.source || this.props.hideBtns) ? // Only show the button if they have things to press

                        (<div className="pro-btns mt-auto">
                            <NavLink to={this.props.view} className={ this.props.view ? "btn ": "btn invisible" } >Learn More</NavLink>
                            <a href={this.props.source} className={this.props.source ? "btn" :"btn invisible"}>Source</a>
                        </div>)
                        :
                        <div className=" pro-btns coming-btns mt-auto">
                            <div className="btn btn-light btn-outline-secondary">Page Coming Soon</div>
                        </div>
                        // <div/>

                        }    

                        
       
                </div>

                <div className="card-footer">
                            <small className="text-muted">{this.props.date}</small>
                </div>
                
            </div>
        )
    }
}

export default Work
import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ExperienceList from '../components/ExperienceList'


const Experience = () => {
  return (
    <div className='experience-page'>
        <Navbar/>
        <ExperienceList/>
        <Footer/>
    </div>
  )
}

export default Experience
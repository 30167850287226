import React, {Component} from 'react'
import SkillDetail from '../SkillDetail'
import "./SkillsCustomCardStyles.css"

class SkillsCustomCard extends Component {
    render() {

        return (
            <section className='base project-skills' id="skills">
                <div className='card card-limit'>
                    <h1>Skills Used</h1>
                    <div id="skill-showcase">
                        <div className='skills-box'>
                            {this.props.skills.map((skill, skillIndex) => (
                                <SkillDetail
                                    key={skillIndex}
                                    imgsrc={skill.imgsrc} 
                                    alt={skill.text} 
                                    text={skill.text} 
                                />
                            ))}
                        </div>
                        
                    </div>
                </div>
            
            
            
            </section>
        )
}
}

export default SkillsCustomCard
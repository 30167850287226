import "./SandPandDStyles.css"
import React, {Component} from 'react'


class SandPandD extends Component {
    render(){ 
        return (
            <section id={this.props.title}>
                <div className=" project-spd card card-limit">
                    <div className="contents">
                        <h2>{this.props.title}</h2>
                        <div className={this.props.reverse ? "reverse info" : "info"}>
                            <div className={this.props.imgsrc & this.propsimgsr ? "img-stack" : "img-stack single"}>
                                {this.props.imgsrc? <img src={this.props.imgsrc} className={this.props.floatImgs ? "img-shadow" : ""} alt={this.props.title}/> : <div/> }
                                {this.props.img2src? <img src={this.props.img2src} className={this.props.floatImgs ? "img-shadow" : ""} alt={this.props.title}/> : <div/> }
                            </div>
                            <div className="text-stack">
                                {this.props.p1 ? <p>{this.props.p1}</p> : <div/> }
                                {this.props.link ?  
                                    <p className="special-link hover-style" ><a className="" href={this.props.link[1]}>
                                        {this.props.link[0]}
                                    </a></p>
                                    : <div />}
                                {this.props.bulletList ? 
                                    <ul>
                                        {this.props.bulletList.map((listItem) => (<li>{listItem}</li>))}
                                    </ul>
                                :<div/>}
                                {this.props.p2 ? <p>{this.props.p2}</p> : <div/> }
                                {this.props.p3 ? <p>{this.props.p3}</p> : <div/> }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SandPandD

import "./HeroimgStyles.css"


import React from 'react'

import {Link} from "react-router-dom"

import pImage from "../assets/NathanielAsia-Cover.png";

const Heroimg = () => {
  return (
    <div className="hero">
      
        <div className="mask">
            <img className="into-img" src={pImage} alt="Nathaniel Asia"/>
        </div>

        <div className="content">
            <p>Hi, Im Nathaniel Asia 👋</p>
            <h1>{" Innovator "}</h1>

            <div>
                <Link to="/projects" className="btn">Projects</Link> 
                <Link to="/experience" className="btn btn-light">Experience</Link> 
            </div>

        </div>
    </div>
  )
}

export default Heroimg
import React from 'react'
import SkillDetail from './SkillDetail'
import {SkillsCardData} from "../Data/SkillsCardData.js"

function SkillsCard() {
  return (
    <section className='base' id="skills">
        <div className='card card-limit'>
            <h1>Skills</h1>

            <div id="skill-showcase">
                {Object.keys(SkillsCardData).map((category) => (
                    <div className="skills-category">
                        <h2>{category}</h2>
                        <div className='skills-box'>
                            {SkillsCardData[category].map((skill, skillIndex) => (
                                <SkillDetail
                                    key={skillIndex}
                                    imgsrc={skill.imgsrc} 
                                    alt={skill.text} 
                                    text={skill.text} 
                                />
                            ))}
                        </div>
                    </div>
                ))}
                
            </div>
        </div>
    
    
    
    </section>
  )
}

export default SkillsCard
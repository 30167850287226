import "./ProjectCardStyles.css"


import React from 'react'
import Work from "./Work.js"
import WorkCardData from "../Data/ProjectCardData.js"

const ProjectCard = () => {
  return (
    <section id="projects">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-3 g-5">
            {WorkCardData.map((val, ind) => {
                return(
                  <div className="col ">
                    <Work 
                    key={ind}
                    imgsrc = {val.imgsrc}
                    title  = {val.title}
                    text   = {val.text}
                    view   = {val.view}
                    source = {val.source}
                    date   = {val.date}
                    hideBtns = {val.hideBtns}
                    />
                    </div>
                )
            })}

          </div>
        </div>
    </section>
  )
}

export default ProjectCard

import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ProjectTitle from '../../components/projectpages/ProjectTitle'
import SandD from '../../components/projectpages/SandD'
import SandPandD from '../../components/projectpages/SandPandD'
import CarouselCard from '../../components/projectpages/CC'

import GearGuroo_logo from "../../assets/Projects/GearGuroo/LOGO.png"
import RideView from "../../assets/Projects/GearGuroo/ggRideView.png"
import SettingView from "../../assets/Projects/GearGuroo/ggSettingView.png"
import breakOut from "../../assets/Projects/GearGuroo/breakOut.jpg"
import GearGuroo from "../../assets/Projects/GearGuroo/GearGuroo.png"
import v1 from "../../assets/Projects/GearGuroo/Uncovered Device Electronics.jpg"
import v1OnBike from "../../assets/Projects/GearGuroo/Covered Electronics mounted to bik for testing.jpg"
import pcb from "../../assets/Projects/GearGuroo/pcb_1.jpg"


import {skillsDict} from "../../Data/SkillsCardData"
import "./GearGurooPageStyles.css"
import SkillsCustomCard from '../../components/projectpages/SkillsCustomCard'

const imageList = [
  GearGuroo, 
  breakOut, 
  v1, 
  v1OnBike, 
  pcb
]

const GearGurooPage = () => {

  const skills = [
    skillsDict["altium"],
    skillsDict["cpp"],
    skillsDict["swift"],
    skillsDict["solidworks"]
  ]

  return (
    <div>
        <Navbar/>

        <ProjectTitle 
          // title="GearGuroo"
          imgsrc={GearGuroo_logo}
        />

        <SandD 
          title="Overview"
          p1="In this team project, we developed an intelligent gear-suggestion system catering to novice cyclists, dynamically recommending and displaying optimal gear changes in response to the rider's speed and incline. My responsibilities encompassed designing and implementing the underlying embedded system responsible for data acquisition and driver interaction. Additionally, I took charge of developing the interactive iOS application, ensuring seamless integration with the physical for a cohesive and user-friendly experience."
          // p2="testing2"
        />

        <SandPandD 
          title="Embedded System"
          imgsrc={breakOut}
          // imgsrc={GearGuroo}
          p1="In my role as the lead electrical engineer for the team, I orchestrated the design and implementation of an embedded system centered on the ESP32 platform. This system served three key functions: firstly, it adeptly managed data acquisition for speed (utilizing hall effect sensors) and rider angle (leveraging an accelerometer); secondly, it established seamless communication with the display via SPI, showcasing real-time information to the user, including current speed, angle, and recommended riding gear; and thirdly, it efficiently handled communication with the user's iOS device."
        />

        <SandPandD 
          title="iOS App"
          imgsrc={RideView}
          img2src={SettingView}
          p1="Leveraging my software development skills, I furthered my contributions to the team's success by developing the iOS companion app. This app was designed to enhance user experience by providing a secondary user interface to the riding platform. Leveraging my expertise in Swift and Xcode, I successfully created and deployed the app, seamlessly integrating it with the device through Bluetooth. This accomplishment not only showcased my proficiency in mobile app development but also highlighted my ability to innovate and contribute valuable features to our project."
          // p2="test"
          reverse={true}
          img_normal={true}
        />

        <SkillsCustomCard 
          skills={skills}
        />

        <CarouselCard
          imgList={imageList}
        />

        <Footer/>
    </div>
  )
}

export default GearGurooPage
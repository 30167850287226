import c_plus_plus from "../assets/logos/c_pp.svg"
import java from "../assets/logos/java-seeklogo.com.svg"
import python from "../assets/logos/python-seeklogo.com.svg"
import git from "../assets/logos/git-seeklogo.com.svg"
import swift from "../assets/logos/Swift_logo_color.svg"
import aws from "../assets/logos/aws.svg" 
import react_logo from "../assets/logos/react-seeklogo.com.svg"
import kube from "../assets/logos/kubernetes-seeklogo.com.svg"
import django from "../assets/logos/django-seeklogo.com.svg"
import altium from "../assets/logos/altium-designer.png"
import simulink from "../assets/logos/Simulink_Logo_(non-wordmark).png"
import matlab from "../assets/logos/Matlab_Logo.png"
import solidworks from "../assets/logos/solidworks.svg"
import fusion360 from "../assets/logos/autodesk-fusion-360-logo-7F72A76397-seeklogo.com.png"

const skillsDict = {
    "altium": {
        imgsrc: altium,
        text: "Altium"
    },
    "simulink":{
        imgsrc: simulink,
        text: "Simulink"
    },
    "matlab":{
        imgsrc: matlab,
        text: "MATLAB"
    },   
    "solidworks":{
        imgsrc: solidworks,
        text: "Solidworks"
    },   
    "fusion360":{
        imgsrc: fusion360,
        text: "Fusion 360"
    },
    "cpp":{
        imgsrc: c_plus_plus,
        text: "C++"
    },
    "django":{
        imgsrc: django,
        text: "Django"
    },
    "java":{
        imgsrc: java,
        text: "Java"
    },
    "python":{
        imgsrc: python,
        text: "Python"
    },
    "react":{
        imgsrc: react_logo,
        text: "React"
    },
    "swift":{
        imgsrc: swift,
        text: "Swift"
    },
    "aws":{
        imgsrc: aws,
        text: "AWS"
    },
    "git":{
        imgsrc: git,
        text: "Git"
    },
    "kubernetes":{
        imgsrc: kube,
        text: "Kubernetes"
    },

}

const SkillsCardData = { 
    "Electrical & Computer Engineering": [
        skillsDict["altium"],
        skillsDict["simulink"],
        skillsDict["matlab"],
        skillsDict["solidworks"],
        skillsDict["fusion360"]    
    ],
    "Software Development": [
        skillsDict["cpp"],
        skillsDict["django"],
        skillsDict["java"],
        skillsDict["python"],
        skillsDict["react"],
        skillsDict["swift"]
    ],
    "Software Deployment" :[
        skillsDict["aws"],
        skillsDict["git"],
        skillsDict["kubernetes"]
    ],


    }



export {
    SkillsCardData,
    skillsDict
}
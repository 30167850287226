import "./FooterStyles.css"


import React from 'react'
import {FaMailBulk} from "react-icons/fa"

const Footer = () => {
  return (
    <section className="footer" id="footer">
        <div className="footer-container card card-limit ">
              <h1 className="center">
                  <FaMailBulk size={30} style={{color:"#000", marginRight: "1rem"}}/>
                  <a className="hover-style" href="mailto:nathaniel.asia@duke.edu">Lets Chat!</a>
              </h1>
        </div>
    </section>
  )
}

export default Footer
import React from 'react'
import Footer from '../components/Footer'
import Heroimg from '../components/Heroimg'
import Navbar from '../components/Navbar'
import SkillsCard from '../components/SkillsCard.js'
import AboutContent from '../components/AboutContent'
import PandE from "../components/PandEbutton.js"



const Home = () => {
  return (
    <div>
        <Navbar/>
        <Heroimg/>
        <AboutContent/>
        <SkillsCard/>
        <PandE/>
        <Footer/>
    </div>
  )
}

export default Home

// test
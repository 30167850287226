import "./ProjectTitleStyles.css"
import React, {Component} from 'react'


class ProjectTitle extends Component {
    render(){ 
        return (
            <section id={this.props.title}>
                <div className=" project-title-card card card-limit">
                    <div className="contents">
                        {this.props.imgsrc ? <img src={this.props.imgsrc} alt={this.props.title} /> : <div/>}
                        {this.props.title ? <h1>{this.props.title}</h1> : <div/>}
                    </div>
                </div>
            </section>
        )
    }
}

export default ProjectTitle
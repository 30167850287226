import "./NavbarStyles.css" 

import React, {useState, useEffect} from 'react'
import {Link, useLocation} from "react-router-dom"
import {FaBars, FaTimes} from "react-icons/fa"
import linkedin_logo from "../assets/logos/linkedin_logo.png"
import github_logo from "../assets/logos/github-mark/github-mark.svg"
import site_logo from "../assets/logos/logo512.png"

const Navbar = () => {

    useEffect(() => { document.title = "Nathaniel Asia" }, []);

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);


    const [color, setColor] = useState(false);
    const changeColor = () => {
        if(window.scrollY >= 80){
            setColor(true);
        }
        else{
            setColor(false);
        }

    }

    window.addEventListener("scroll", changeColor);

    const location = useLocation();
    const notHome = location.pathname !== '/';


  return (
    <div id="nav">
        <div className={color | notHome ? "header header-bg" : "header {{navbarClass}}"}>
            <Link to="/"> 
                {/* <h2>Nathaniel Asia</h2> */}
                <img className="site-logo" src={site_logo} alt="N Logo"></img>
            </Link>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li><Link className="hover-style" to="/">Home</Link></li>
                {/* <li><a className="hover-style" href="/#about">About</a></li> */}
                <li><Link className="hover-style" to="/experience">Experience</Link></li>
                <li><Link className="hover-style" to="/projects">Projects</Link></li>
                <li><a className="hover-style" href="#footer">Contact</a></li>
                <li><a href="https://github.com/NateAsia"><img className="github_logo" src={github_logo} alt="github"></img></a></li>
                <li><a href="https://www.linkedin.com/in/NathanielAsia"><img src={linkedin_logo} alt="linkedin"></img></a></li>
            </ul>


            <div className="hamburger" onClick={handleClick}>
                {click ? 
                    (<FaTimes size={20} style={{color:"#000"}}/>) : (<FaBars size={20}/>)
                }
                
                
            </div>

            
        </div>
        
        <div className={notHome ? "nav-cover" : ""}> </div>

    </div>

  )
}

export default Navbar
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import "./CCStyles.css"
import React from 'react'
import Slider from 'react-slick';
;

const CarouselCard = ({imgList, floatImgs}) => {

    const settings = { 
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <section id="skills">
            <div className=" project-cc card card-limit">
                <h1>Images</h1>

            <Slider {...settings}>
                {imgList.map((imgsrc, index) => (
                    <div key={index}>
                        <img key={index} class={floatImgs ? "d-block w-100 img-shadow" : "d-block w-100"} src={imgsrc} alt={`${index + 1}`}/>
                    </div>
                ))}
            </Slider>
            <div className='empty-space'/>
                
            </div>
        </section>
    )
}

export default CarouselCard
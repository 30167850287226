import React from "react";
import "./index.css";

import Home     from "./routes/Home.js";
import Project  from "./routes/Project.js";

import {Route, Routes}  from "react-router-dom";
import GearGurooPage from "./routes/projects/GearGurooPage";
import NoPage from "./routes/NoPage";
import Experience from "./routes/Experience.js";
import SteeringWheel from "./routes/projects/SteeringWheel.js";
import AutoDRS from "./routes/projects/AutoDRS.js";
import ShiftControl from "./routes/projects/ShiftControl.js";

function App() {
  return (
    <>
    <Routes>

      {/* Main Pages */}
      <Route path="/"         element={<Home/>} />
      <Route path="/projects"  element={<Project/>} />
      <Route path="/experience"  element={<Experience/>} />

      {/* Projects */}
      <Route path="/projects/gearguroo"  element={<GearGurooPage/>} />
      <Route path="/projects/racing-steering-wheel"  element={<SteeringWheel/>} />
      <Route path="/projects/auto-drag-reduction-system"  element={<AutoDRS/>} />
      <Route path="/projects/shift-control-strategy" element={<ShiftControl/>} />

      {/* Developmental */}
      <Route path="*" element={<NoPage />} />

      
    </Routes>

    </>
  );
}

export default App;

import "./AboutContentStyles.css"

import React from 'react'

import nate_2_img from "../assets/NathanielAsia-playful.png"

const AboutContent = () => {
  return (
    <section className="about" id="about">

        <div className="center card card-limit">
            <h1>About Me</h1>
            <p> 
                Hey 👋, I'm Nathaniel (Nate) Asia, a student currently pursuing my degree in Electrical Engineering and Computer Science at Duke University. 
                My enthusiasm for technology and engineering has propelled me to explore the intersection of these dynamic fields. 
                During a transformative summer at Rubrik in Palo Alto, California, and a previous internship at Comcast in Philadelphia, PA, I honed my skills and gained invaluable hands-on experience. 
                Beyond internships, my year-round role as an electrical engineering lead on the Duke Motorsports team has provided me with a platform to drive meaningful advancements, further fueling my passion for innovation.
            </p>
            <p>    
                In my role as an Electrical Engineer, I have successfully designed and implemented a range of cutting-edge projects, from hardware systems to embedded solutions. 
                My expertise spans circuit design, signal processing, and control systems, allowing me to bring ideas to life in the tangible world. 
                Simultaneously, as a Software Engineer, I delve into the virtual realm, leveraging my programming prowess to create efficient and elegant software solutions. 
                Whether it's developing robust algorithms, designing user-friendly interfaces, or optimizing code for performance, I am committed to delivering solutions that seamlessly integrate hardware and software components.
            </p>
            <p> 
                Driven by a passion for continuous learning, I stay at the forefront of technological advancements. 
                My commitment to staying current with industry trends and emerging technologies ensures that I bring a forward-thinking perspective to every project. 
                Beyond my technical expertise, I value collaboration and effective communication. 
                I believe that the combination of technical skills and interpersonal abilities is crucial for successfully navigating the complex landscape of engineering projects. 
            </p>
            <p> 
                As you explore my portfolio, you'll gain insight into my diverse skill set, innovative projects, and the enthusiasm I bring to every endeavor. 
                I look forward to connecting with like-minded individuals and organizations who share a vision for pushing the boundaries of what technology can achieve.
            </p>
        </div>


    </section>
  )
}

export default AboutContent
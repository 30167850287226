import "./ExperienceListStyles.css"

import React from 'react'
import ExperienceCardData from "../Data/ExperienceCardData"

function ExperienceList() {
  return (
    <div className="experience-section">
            {Object.keys(ExperienceCardData).map((experience) => (
                <section id={experience} >
                    <div className='card card-limit'>
                        <div className="card-head">
                            <img src={ExperienceCardData[experience].logo} alt=""/>
                            <div className="inner">
                                <h4>{experience} - {ExperienceCardData[experience].job_title}</h4>
                                <p>{ExperienceCardData[experience].location}, {ExperienceCardData[experience].timeline}</p>
                            </div>
                        </div>
                        <p>
                            {ExperienceCardData[experience].description}
                        </p>
                    </div>
                    
                </section>
            ))}
    </div>
  )
}

export default ExperienceList